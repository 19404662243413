import './investing.css'

const Investing = () => {

    return (
        <div className='invest'>
            <div className='strategy'>
                <h1 className='disclaimer'>USE AT YOUR OWN RISK:</h1>
                <div className='market'>
                    <h1>Step 1 - Assess The Market</h1>
                    <p>Go to <a href="https://www.cnn.com/markets/fear-and-greed" target="_blank" rel="noreferrer">CNN Fear & Greed Index.</a> and assess the general mood of the US market.</p>
                    <p>September 8, 2024:  39</p>
                    <div className='marketEnvironments'>
                        <p className="fearAndGreed extremeFear">Extreme Fear = In the mood for buying, never selling.</p>
                        <p className="fearAndGreed fear">Fear = In the mood for buying.</p>
                        <p className="fearAndGreed neutral">Neutral = Do something else.</p>
                        <p className="fearAndGreed greed">Greed = In the mood for selling.</p>
                        <p className="fearAndGreed extremeGreed">Extreme Greed = In the mood for selling, never buying.</p>
                    </div>
                </div>
                <div className='buy'>
                    <h1>Step 2 - Lookin' To Buy</h1>
                    <p>The amount of buying should increase in a down market and decrease in an up market.</p>
                    <div className='marketEnvironments'>
                        <p className="fearAndGreed extremeFear">Hope you didn't spend it all yet, because everything's on sale.</p>
                        <p className="fearAndGreed fear">If there's any cash left, look for bargains on good investments, otherwise wait.</p>
                        <p className="fearAndGreed neutral">Deals will be harder to find.</p>
                        <p className="fearAndGreed greed">Hope you were mostly all in before the market got here.</p>
                        <p className="fearAndGreed extremeGreed">Don't even think about it!</p>
                    </div>
                </div>
                <div className='sell'>
                    <h1>Step 3 - Lookin' To Cash In</h1>
                    <p>Goals should be reasonable.  5% in 3-5 months or 10% in 6-10 months in a growing market is reasonable.  If we buy during a down market, it may make sense to be more aggressive.  As the market heads towards Greed or Extreme Greed, it might make sense to lower the target.  If the market is not yet back to growth, then patience will be required.</p>
                    <div className='marketEnvironments'>
                        <p className="fearAndGreed extremeFear">Don't even think about it!</p>
                        <p className="fearAndGreed fear">Anything look good to buy?</p>
                        <p className="fearAndGreed neutral">Does the lawn need mowing?</p>
                        <p className="fearAndGreed greed">Should be starting to hit some of your targets soon and getting some cash.</p>
                        <p className="fearAndGreed extremeGreed">I hope your mostly in cash by now.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Investing