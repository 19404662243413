import { useMemo, useState } from "react";
import "./comic.css";
import storyBoard from "./panelList.json"

const Comic = ({viewerWidth, viewerHeight}) => {
    const [ zoom, setZoom ] = useState(false);
    const [ zoomImage, setZoomImage ] = useState("");
    const [ pageSelection, setPageSelection ] = useState(0);

    const currentPage = useMemo(() => { 
        const availableWidth = viewerWidth - 52;
        const availableHeight = viewerHeight - 114;
        const maxColumns = Math.floor(availableWidth / 320);
        let columns = 1;
        const maxRows = Math.floor(availableHeight / 240);
        let rows = 1;
        let imageUnit = 0;

        if (maxColumns > 3) {
            columns = 3;
        } else {
            columns = maxColumns;
        };
        if (maxRows > 2) {
            rows = 2;
        } else {
            rows = maxRows;
        }

        if (Math.floor(availableWidth / (columns * 4)) < Math.floor(availableHeight / (rows * 3))) {
            imageUnit = Math.floor(Math.floor(availableWidth / (columns * 4))) - 2;
        } else {
            imageUnit = Math.floor(Math.floor(availableHeight / (rows * 3))) - 4;
        };
        const comic = [];
        const panelsPerPage = columns * rows;
        storyBoard.forEach((panel, index) => {
            if (!comic[Math.floor((index)/panelsPerPage)]) {
                comic[Math.floor((index)/panelsPerPage)] = [];
            };
            comic[Math.floor((index)/panelsPerPage)].push(panel);
        });

        return { "panelWidth": imageUnit * 4, "panelHeight": imageUnit * 3, "comic": comic }
     }, [viewerWidth, viewerHeight]);

    const panelZoom = (selection) => {
        setZoomImage(selection);
        setZoom(true);
    };

    const closeZoom = () => {
        setZoom(false);
    };

    return (
        <div className="comicDiv">
            <div className="comicNavigation">
                {pageSelection !== 0 && <button onClick={() => setPageSelection(pageSelection - 1)}>&larr;</button>}
            </div>
            <div className="comicLayout">
                {currentPage["comic"][pageSelection].map((file) => 
                        <div key={file} className="comicPanel" onClick={() => panelZoom("./comic/" + file)}>
                            <img width={currentPage.panelWidth} height={currentPage.panelHeight} alt="Click to enlarge" src={"./comic/" + file} />
                        </div>
                    )
                }
            </div>
            <div className="comicNavigation">
                {pageSelection !== currentPage["comic"].length - 1 && <button onClick={() => setPageSelection(pageSelection + 1)}>&rarr;</button>}
            </div>
            {zoom && <div class="popup" id="popup">
                <div class="popup-content">
                    <span className="close-btn" id="close-btn" onClick={closeZoom}>&times;</span>
                    <img src={zoomImage} alt="Full size" />
                </div>
            </div>}
        </div>
    )
}

export default Comic